<template>
  <div class="home">
   <a href="https://wa.me/message/LB64QCX6AOQHI1">
    <img src="../assets/button.png" class="button"></a> 
    <img alt="Vue logo" src="../assets/1_01.jpg">
    <img alt="Vue logo" src="../assets/1_02.jpg">
    <img alt="Vue logo" src="../assets/1_03.jpg">
    <img alt="Vue logo" src="../assets/1_04.jpg" style="margin-bottom:100px">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  }
}
</script>
<style scoped>
.home{
  width: 100%;
    height: 100vh;
    max-width: 420px;
    margin: 0 auto;
    background-color: #fff;
    padding-bottom: 70px;
    position: relative;
  }
  img {
  width: 100%;
  height: auto;
}
  .button{
    position: fixed;
  right: 0px;
  bottom: 0px;
  /* top: 460px; */
  transition: right 0.5s;
}


</style>
